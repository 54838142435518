/* eslint-disable no-case-declarations */
import get from 'lodash/get';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import {
  REQUESTED,
  SUCCEEDED,
  FAILED,
  CLEAR_RESPONSE,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const resourceState = {
  isError: false,
  isLoading: false,
  isInitialState: true,
  data: [],
};

export const pagination = {
  page: 1,
  perPage: 25,
  perPageOptions: [
    { label: 25, value: 25 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ],
};

function reduceData(state, action) {
  const { payload } = action;

  if (!payload.method && payload.response) {
    return get(payload, ['response', 'data']);
  }

  switch (payload.method) {
    case methods.READ:
      return payload.response.data;
    case methods.CREATED:
      return [
        ...state.data,
        ...(
          isArray(payload.response.data)
            ? payload.response.data.map(item => ({
              ...item,
              isNew: true,
            }))
            : [{ ...payload.response.data, isNew: true }]
        ),
      ];
    case methods.UPDATE:
    case methods.DELETE:
      return state.data.map(item => ({
        ...item,
        isLoading: item.id === payload.id || item.isLoading,
      }));
    case methods.UPDATED:
      return state.data.map(item => ({
        ...item,
        isLoading: item.id === payload.id ? false : item.isLoading,
        ...(item.id === payload.id && payload.updates),
      }));
    case methods.DELETING:
      return state.data.map(item => (
        item.id === payload.id ? ({ ...item, isDeleting: true }) : item
      ));
    case methods.DELETED:
      return state.data.filter(item => item.id !== payload.id);
    case methods.FAIL:
      return state.data.map(item => ({
        ...item,
        ...(item.id === payload.id && item.isDeleting ? { isDeleting: false } : {}),
        ...(item.id === payload.id && item.isLoading ? { isLoading: false } : {}),
      }));
    default:
      return state.data;
  }
}

export const reducePagination = (state, payload) => {
  const responsePagination = get(payload, 'response.pagination', null);

  if (!responsePagination) return {};

  return {
    pagination: {
      ...state.pagination,
      ...responsePagination,
    },
  };
};

export const reduceMeta = (state, payload) => {
  if (!has(payload, 'response.meta') || !['created', 'deleted'].includes(payload.method)) { return {}; }

  const { response } = payload;
  const { meta: { perPage, totalItems, page } } = response;

  const newTotalItem = payload.method === 'created' ? totalItems + 1 : totalItems - 1;
  const newNumberOfPages = Math.ceil(newTotalItem / perPage);


  const meta = {
    ...response.meta,
    perPage,
    page: page > newNumberOfPages ? newNumberOfPages : page,
    numberOfPages: newNumberOfPages,
    totalItems: newTotalItem,
  };

  return {
    meta,
    response: {
      ...response,
      meta,
    },
  };
};

export default function resourceReducerFactory(
  initialState = { ...resourceState, pagination },
  actionMapper = {
    REQUESTED,
    SUCCEEDED,
    FAILED,
    CLEAR_RESPONSE,
    CLEAR_MESSAGE,
  },
) {
  return (state = initialState, action) => {
    const { type, payload = {} } = action;

    const { id, method, ...params } = payload;

    switch (type) {
      case actionMapper[REQUESTED]:
        return {
          ...state,
          data: reduceData(state, action),
          ...(id ? { id } : {}),
          isError: false,
          isLoading: true,
          ...params,
        };
      case actionMapper[SUCCEEDED]:
        return {
          ...state,
          ...payload.response,
          data: reduceData(state, action),
          ...(id ? { id } : {}),
          isError: false,
          isLoading: false,
          isInitialState: false,
          ...params,
          ...reducePagination(state, params),
          ...reduceMeta(state, payload),
        };
      case actionMapper[FAILED]:
        return {
          ...state,
          data: reduceData(state, action),
          ...(id ? { id } : {}),
          isError: true,
          isLoading: false,
          message: get(payload.error, 'message'),
          error: payload.error,
          ...params,
        };
      case actionMapper[CLEAR_RESPONSE]:
        return {
          ...initialState,
        };
      case actionMapper[CLEAR_MESSAGE]:
        return {
          ...state,
          ...params,
          isError: false,
          message: '',
          error: {},
        };
      default:
        return state;
    }
  };
}
