import * as customerActions from 'actions/customer';
import { actions as historyActions } from 'actions/customer-history-actions';
import {
  actions as transactionsActions,
  reversalActions as transactionReversalActions,
} from 'actions/customer-transactions';
import resourceReducerFactory, { resourceState, pagination } from 'reducers/resource';

export const initialState = {
  isInitialState: true,
  securityDeposit: null,
  reseller: {},
  status: null,
  summary: false,
  apiSecretKey: null,
  preferredEdgeStrategy: {
    naptr: 'sip.flowroute.com',
  },
  techprefix: null,
  runningBalance: 0,
  defaultPaymentSource: null,
  isLoading: false,
  requestError: null,
  setPopResponse: null,
  isSettingPop: false,
  hasVerifiedEmail: true,
  formData: {
    edge_set_strategy: '',
  },
  resetSipPasswordResponse: null,
  isResettingSipPassword: false,
  isOutboundSipEnabled: false,
  history: { ...resourceState },
  transactions: {
    ...resourceState,
    pagination: { ...pagination, perPage: 100 },
    reversals: {
      ...resourceState,
      marked: [],
    },
  },
};

const historyReducer = resourceReducerFactory(initialState.history, historyActions);
const transactionsReducer = resourceReducerFactory(initialState.transactions, transactionsActions);
const transactionReversalReducer = resourceReducerFactory(
  initialState.transactions.reversals,
  transactionReversalActions,
);

const customer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case transactionReversalActions.CHECK: {
      let { marked } = state.transactions.reversals;
      if (marked.includes(payload.id)) {
        if (payload.massCheck) {
          marked = [];
        } else {
          marked = marked.filter(id => id !== payload.id);
        }
      } else if (payload.massCheck) {
        marked = state.transactions.data.filter(
          t => !t.type.isCredit && !t.revertTransaction,
        ).map(t => t.id);
      } else {
        marked = [...marked, payload.id];
      }
      return {
        ...state,
        transactions: {
          ...state.transactions,
          reversals: {
            ...state.transactions.reversals,
            marked,
          },
        },
      };
    }
    case transactionReversalActions.FAILED:
    case transactionReversalActions.REQUESTED:
    case transactionReversalActions.SUCCEEDED: {
      const { transactions } = state;
      const { id: transactionsReverting } = payload;
      const revertSucceeded = type === transactionReversalActions.SUCCEEDED;
      const marked = revertSucceeded ? [] : transactions.reversals.marked;
      const data = transactions.data.map((transaction) => {
        const isIteratee = transactionsReverting.includes(transaction.id);
        if (isIteratee && revertSucceeded) {
          return {
            ...transaction,
            revertTransaction: true,
          };
        }
        const isLoading = type === transactionReversalActions.REQUESTED && isIteratee;
        return { ...transaction, isLoading };
      });

      return {
        ...state,
        transactions: {
          ...state.transactions,
          data,
          reversals: {
            ...transactionReversalReducer(state.transactions.reversals, action),
            marked,
          },
        },
      };
    }
    case transactionsActions.FAILED:
    case transactionsActions.REQUESTED:
    case transactionsActions.SUCCEEDED:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          ...transactionsReducer(state.transactions, action),
        },
      };
    case transactionsActions.CLEAR_MESSAGE:
      return {
        ...state,
        transactions: {
          ...transactionsReducer(state.transactions, action),
          reversals: transactionReversalReducer(state.transactions.reversals, action),
        },
      };
    case historyActions.FAILED:
    case historyActions.CLEAR_MESSAGE:
    case historyActions.REQUESTED:
    case historyActions.SUCCEEDED:
      return {
        ...state,
        history: historyReducer(state.history, action),
      };
    case customerActions.CUSTOMER_SUCCEEDED:
      return {
        ...state,
        isInitialState: false,
        isLoading: false,
        requestError: null,
        formData: {
          ...initialState.formData,
          edge_set_strategy: payload.preferredEdgeStrategy
            ? payload.preferredEdgeStrategy.id
            : null,
          type: state.formData.type,
        },
        ...action.payload,
      };
    case customerActions.CUSTOMER_REQUESTED:
      return { ...state, isLoading: true, requestError: null };
    case customerActions.CUSTOMER_FAILED:
      return { ...state, isLoading: false, requestError: action.error };
    case customerActions.SET_POP_INPUT:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload.formData,
        },
      };
    case customerActions.SET_POP_REQUESTED:
      return {
        ...state,
        setPopResponse: null,
        isSettingPop: true,
      };
    case customerActions.SET_POP_SUCCEEDED:
      const edgeStrategy = payload.setPopResponse.data[0];
      return {
        ...state,
        ...payload,
        formData: {
          ...initialState.formData,
          edge_set_strategy: edgeStrategy.id,
          type: state.formData.type,
        },
        preferredEdgeStrategy: edgeStrategy,
        isSettingPop: false,
      };
    case customerActions.SET_POP_FAILED:
      return {
        ...state,
        ...payload,
        isSettingPop: false,
      };
    case customerActions.RESET_SIP_PASSWORD_REQUESTED:
      return {
        ...state,
        resetSipPasswordResponse: null,
        isResettingSipPassword: true,
      };
    case customerActions.RESET_SIP_PASSWORD_SUCCEEDED:
      return {
        ...state,
        ...payload,
        sipPassword: payload.resetSipPasswordResponse.data[0],
        isResettingSipPassword: false,
      };
    case customerActions.RESET_SIP_PASSWORD_FAILED:
      return {
        ...state,
        ...payload,
        isResettingSipPassword: false,
      };
    case customerActions.CLEAR_RESPONSE: {
      const responses = ['setPopResponse', 'resetSipPasswordResponse'];
      if (responses.includes(payload.responseKey)) {
        return {
          ...state,
          [payload.responseKey]: null,
        };
      }
    }
    default:
      return state;
  }
};

export default customer;
