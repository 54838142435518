import OutboundAllowedIP from 'app/api/outbound-allowed-ip';

import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'OUTBOUND_ALLOWED_IPS/REQUESTED',
  [FAILED]: 'OUTBOUND_ALLOWED_IPS/FAILED',
  [SUCCEEDED]: 'OUTBOUND_ALLOWED_IPS/SUCCEEDED',
  [CLEAR_MESSAGE]: 'OUTBOUND_ALLOWED_IPS/CLEAR_MESSAGE',
});

const requested = action[REQUESTED];
const succeeded = action[SUCCEEDED];
const failed = action[FAILED];
export const clearMessage = action[CLEAR_MESSAGE];

export const createOutboundAllowedIpRequested = () => requested(null, {
  isCreatingOutboundAllowedIp: true,
});

export const createOutboundAllowedIpSucceeded = response => succeeded({
  data: response.data,
  message: response.message,
}, null, {
  method: methods.CREATED,
  isCreatingOutboundAllowedIp: false,
});

export const createOutboundAllowedIpFailed = err => failed(err, null, {
  isCreatingOutboundAllowedIp: false,
});

export const deleteOutboundAllowedIpRequested = id => requested(id, {
  isDeletingOutboundAllowedIp: true,
  method: methods.DELETING,
});

export const deleteOutboundAllowedIpSucceeded = (response, id) => succeeded({
  data: response.data,
  message: response.message,
}, id, {
  method: methods.DELETED,
  isDeletingOutboundAllowedIp: false,
});

export const deleteOutboundAllowedIpFailed = (err, id) => failed(err, id, {
  isDeletingOutboundAllowedIp: false,
  method: methods.FAIL,
});

export const outboundAllowedIpsRequested = (id = null) => requested(id, {
  isLoadingOutboundAllowedIps: true,
});

export const outboundAllowedIpsSucceeded = response => succeeded({
  data: response.data,
  pagination: response.meta,
}, null, {
  isLoadingOutboundAllowedIps: false,
});

export const outboundAllowedIpsFailed = (err, id) => failed(err, id, {
  isLoadingOutboundAllowedIps: false,
  method: methods.FAIL,
});

export const outboundAllowedIpsClearResponse = () => clearMessage({
  response: {},
});

export function createOutboundAllowedIp(params) {
  return (dispatch) => {
    dispatch(createOutboundAllowedIpRequested());

    return OutboundAllowedIP.post(params)
      .then(response => dispatch(createOutboundAllowedIpSucceeded(response)))
      .catch(error => dispatch(createOutboundAllowedIpFailed(error)));
  };
}

export function deleteOutboundAllowedIp(outboundAllowedIpId) {
  return (dispatch) => {
    dispatch(deleteOutboundAllowedIpRequested(outboundAllowedIpId));

    return OutboundAllowedIP.destroy(outboundAllowedIpId)
      .then(response => dispatch(deleteOutboundAllowedIpSucceeded(response, outboundAllowedIpId)))
      .catch(error => dispatch(deleteOutboundAllowedIpFailed(error, outboundAllowedIpId)));
  };
}

export function fetchOutboundAllowedIps(query) {
  return (dispatch) => {
    dispatch(outboundAllowedIpsRequested());

    return OutboundAllowedIP.get(query)
      .then(payload => payload)
      .then(body => dispatch(outboundAllowedIpsSucceeded(body)))
      .catch(error => dispatch(outboundAllowedIpsFailed(error)));
  };
}

export function editOutboundAllowedIps(id, data) {
  return (dispatch) => {
    dispatch(requested(id, { method: methods.UPDATE }));

    return OutboundAllowedIP.put(id, data)
      .then(response => dispatch(succeeded(
        response,
        id,
        {
          method: methods.UPDATED,
          updates: data,
        },
      )))
      .catch((error) => {
        dispatch(failed(error, id, { method: methods.FAIL }));

        throw error;
      });
  };
}
