import ProfileMessage from 'app/api/profile-message';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'PROFILE_MESSAGES/REQUESTED',
  [FAILED]: 'PROFILE_MESSAGES/FAILED',
  [SUCCEEDED]: 'PROFILE_MESSAGES/SUCCEEDED',
  [CLEAR_MESSAGE]: 'PROFILE_MESSAGES/CLEAR_MESSAGE',
});

const requested = action[REQUESTED];
const succeeded = action[SUCCEEDED];
const failed = action[FAILED];
export const clearMessage = action[CLEAR_MESSAGE];

export function get() {
  return (dispatch) => {
    dispatch(requested());

    return ProfileMessage.get()
      .then(response => dispatch(succeeded(response)))
      .catch(error => dispatch(failed(error)));
  };
}

export function edit(id, profileMessage) {
  return (dispatch) => {
    dispatch(requested(id, { method: methods.UPDATE }));

    return ProfileMessage.edit(id, profileMessage)
      .then(response => (
        dispatch(succeeded(
          response,
          id,
          {
            method: methods.UPDATED,
            updates: profileMessage,
          },
        ))
      ))
      .catch((error) => {
        dispatch(failed(error));

        throw error;
      });
  };
}

export function create(profileMessage) {
  return (dispatch) => {
    dispatch(requested());

    return ProfileMessage.create(profileMessage)
      .then(response => dispatch(succeeded(response, null, { method: methods.CREATED })))
      .catch(error => dispatch(failed(error)));
  };
}

export function destroy(id) {
  return (dispatch) => {
    dispatch(requested(id, { method: methods.DELETE }));

    return ProfileMessage.destroy(id)
      .then((response) => {
        dispatch(succeeded({}, id, { method: methods.DELETING }));

        return new Promise(resolve => setTimeout(() => (
          resolve(dispatch(succeeded(response, id, { method: methods.DELETED })))
        ), 1500));
      })
      .catch(error => dispatch(failed(error)));
  };
}
