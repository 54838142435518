import CustomerHistory from 'app/api/customer-history';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const { dispatchers: action, actionMap: actions } = resourceActionsFactory({
  [REQUESTED]: 'CUSTOMER_HISTORY_REQUESTED',
  [FAILED]: 'CUSTOMER_HISTORY_FAILED',
  [SUCCEEDED]: 'CUSTOMER_HISTORY_SUCCEEDED',
  [CLEAR_MESSAGE]: 'CUSTOMER_HISTORY_CLEAR_MESSAGE',
});

export const clearMessage = action[CLEAR_MESSAGE];

export function get(customer) {
  return (dispatch) => {
    dispatch(action[REQUESTED](customer));
    return CustomerHistory.get(customer).then((history) => {
      dispatch(action[SUCCEEDED](
        { data: history.data },
        customer,
        { method: methods.READ },
      ));
    }).catch(e => dispatch(action[FAILED](e, customer)));
  };
}
