import CustomerAPIKey from 'app/api/customer-api-key';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'CUSTOMER_API_KEY/REQUESTED',
  [FAILED]: 'CUSTOMER_API_KEY/FAILED',
  [SUCCEEDED]: 'CUSTOMER_API_KEY/SUCCEEDED',
  [CLEAR_MESSAGE]: 'CUSTOMER_API_KEY/CLEAR_MESSAGE',
});

const requested = action[REQUESTED];
const succeeded = action[SUCCEEDED];
const failed = action[FAILED];
export const clearCustomerAPIKey = action[CLEAR_MESSAGE];

export function getCustomerAPIKey(query = {}) {
  return (dispatch) => {
    dispatch(requested(null));

    return CustomerAPIKey.get(query)
      .then(response => dispatch(succeeded(response)))
      .catch(error => dispatch(failed(error)));
  };
}

export function createCustomerAPIKey(data) {
  return (dispatch, getState) => {
    dispatch(requested(null));

    const { meta, links } = getState().customerAPIKey.response;

    return CustomerAPIKey.create(data)
      .then((response) => {
        dispatch(
          succeeded(
            {
              meta,
              links,
              ...response,
            },
            null,
            { method: methods.CREATED },
          ),
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(failed(error, null));

        throw error;
      });
  };
}

export function editCustomerAPIKey(id, data) {
  return (dispatch, getState) => {
    dispatch(requested(id, { method: methods.UPDATE }));

    const { meta, links } = getState().customerAPIKey.response;

    return CustomerAPIKey.edit(id, data)
      .then(response => dispatch(
        succeeded(
          {
            meta,
            links,
            ...response,
          },
          id,
          {
            method: methods.UPDATED,
            updates: response.data,
          },
        ),
      ))
      .catch((error) => {
        dispatch(failed(error, id, { method: methods.FAIL }));

        throw error;
      });
  };
}

export function destroyCustomerAPIKey(id) {
  return (dispatch, getState) => {
    dispatch(requested(id, { method: methods.DELETE }));

    const { meta, links } = getState().customerAPIKey.response;

    return CustomerAPIKey.destroy(id)
      .then((response) => {
        dispatch(succeeded({}, id, { method: methods.DELETING }));

        return new Promise(resolve => setTimeout(
          () => resolve(
            dispatch(
              succeeded(
                {
                  meta,
                  links,
                  ...response,
                },
                id,
                {
                  method: methods.DELETED,
                  updates: response.data,
                },
              ),
            ),
          ),
          400,
        ));
      })
      .catch((error) => {
        dispatch(failed(error, id, { method: methods.FAIL }));

        throw error;
      });
  };
}

export function patchCustomerAPIKey(id, data) {
  return (dispatch, getState) => {
    dispatch(requested(id, { method: methods.UPDATE }));

    const { meta, links } = getState().customerAPIKey.response;

    return CustomerAPIKey.patch(id, data)
      .then(response => dispatch(
        succeeded(
          {
            meta,
            links,
            ...response,
          },
          id,
          {
            method: methods.UPDATED,
            updates: response.data,
          },
        ),
      ))
      .catch((error) => {
        dispatch(failed(error, id, { method: methods.FAIL }));

        throw error;
      });
  };
}
