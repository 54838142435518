import DIDHistory from 'app/api/did-history';
import { requestDidNotes } from 'actions/did-notes';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCCEEDED,
} from 'actions/resource';

export const { dispatchers, actionMap: actions } = resourceActionsFactory({
  [REQUESTED]: 'DID_HISTORY_REQUESTED',
  [FAILED]: 'DID_HISTORY_FAILED',
  [SUCCEEDED]: 'DID_HISTORY_SUCCEEDED',
});

export function requestDidHistory(did, query) {
  return (dispatch) => {
    dispatch(dispatchers[REQUESTED]());
    return DIDHistory.get(did, query)
      .then((response) => {
        dispatch(dispatchers[SUCCEEDED](response));
        dispatch(requestDidNotes(did, query));
      })
      .catch(error => dispatch(dispatchers[FAILED](error)));
  };
}
