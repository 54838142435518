import CDRsExports from 'app/api/cdrs-exports';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';
import { prepareBody } from 'app/utils';

export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'CDRS_EXPORTS/REQUESTED',
  [FAILED]: 'CDRS_EXPORTS/FAILED',
  [SUCCEEDED]: 'CDRS_EXPORTS/SUCCEEDED',
  [CLEAR_MESSAGE]: 'CDRS_EXPORTS/CLEAR_MESSAGE',
});

const requested = action[REQUESTED];
const succeeded = action[SUCCEEDED];
const failed = action[FAILED];
export const clearCdrsExports = action[CLEAR_MESSAGE];

export function getCdrsExports(query = {}) {
  return (dispatch, getState) => {
    const { credentials } = getState();
    dispatch(requested(null, { message: '' }));

    return CDRsExports.get({ query, credentials })
      .then(response => dispatch(succeeded(response)))
      .catch(error => dispatch(failed(error)));
  };
}

export function createCdrsExports(data) {
  return (dispatch, getState) => {
    const { credentials } = getState();
    dispatch(requested(null, { isCreating: true }));

    return CDRsExports.create({
      credentials,
      body: prepareBody({
        data: {
          attributes: data,
          type: 'cdrexport',
        },
      }),
    })
      .then((response) => {
        dispatch(succeeded({
          data: response.data,
        }, null, { method: methods.CREATED, isCreating: false }));
      })
      .catch((error) => {
        dispatch(failed({ message: error.message.replace('Error 422: ', '') }, null, { isCreating: false }));
        throw error;
      });
  };
}
