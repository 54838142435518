import DIDNotes from 'app/api/did-notes';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const { dispatchers, actionMap: actions } = resourceActionsFactory({
  [REQUESTED]: 'DID_NOTES_REQUESTED',
  [FAILED]: 'DID_NOTES_FAILED',
  [SUCCEEDED]: 'DID_NOTES_SUCCEEDED',
  [CLEAR_MESSAGE]: 'CLEAR_DID_NOTES_MESSAGE',
});

export const clearMessage = dispatchers[CLEAR_MESSAGE];

export function updateNote(did, noteId, note) {
  return (dispatch) => {
    dispatch(dispatchers[REQUESTED](noteId, { method: methods.UPDATE }));
    return DIDNotes.edit(did, noteId, note)
      .then(() => dispatch(
        dispatchers[SUCCEEDED]({ message: 'Note successfully edited' }, noteId, {
          method: methods.UPDATED,
          updates: { note },
        }),
      ))
      .catch(e => dispatch(dispatchers[FAILED](e, noteId)));
  };
}

export function requestDidNotes(did, query) {
  return (dispatch) => {
    dispatch(dispatchers[REQUESTED]());
    return DIDNotes.get(did, query)
      .then((response) => {
        dispatch(dispatchers[SUCCEEDED]({ data: response.data }));
      })
      .catch((error) => {
        if (error.message === 'Error: That page contains no results') {
          dispatch(dispatchers[SUCCEEDED]({ data: [] }));
        } else {
          dispatch(dispatchers[FAILED](error));
        }
      });
  };
}

export function createNote(did, note) {
  return (dispatch) => {
    dispatch(dispatchers[REQUESTED]());
    return DIDNotes.create(did, note)
      .then((response) => {
        dispatch(dispatchers[SUCCEEDED](response, null, { method: methods.CREATED }));
      })
      .catch(error => dispatch(dispatchers[FAILED](error)));
  };
}
